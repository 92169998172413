export function createQueryParams (params) {
  var qs = [];
  for (var q in params) {
    var p = params[q];
    if(typeof(p) == 'string') {
      qs.push(`${q}=${encodeURIComponent(p)}`);
    } else {
      for (var i=0; i<p.length; i++) {
        qs.push(`${q}=${encodeURIComponent(p[i])}`);
      }
    }
  }
  return qs.join('&');
}

export function slugify(text)
{
  text = text.toString().toLowerCase()
    .replace(/\s+/g, '-')           // Replace spaces with -
    .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
    .replace(/\-\-+/g, '-')         // Replace multiple - with single -
    .replace(/^-+/, '')             // Trim - from start of text
    .replace(/-+$/, '');            // Trim - from end of text

  return text.slice(0, 50);
}

export function first (key, results, flatten_list) {
  var first = results[key].edges[0].node;

  convert_id(first);
  if (flatten_list) {
    flatten(first, flatten_list);
  }

  return first;
}

export function extract_nodes (obj, flatten_list) {
  var results = [];
  obj.edges.forEach(function (n) {
    var node = n.node;
    convert_id(node);
    if (flatten_list) {
      flatten(node, flatten_list);
    }

    results.push(node);
  });
  return results;
}

export function flatten(obj, field_list){
  field_list.forEach(function (field) {
    if (obj[field]) {
      var flat = [];
      obj[field].edges.forEach(function (n) {
        flat.push(convert_id(n.node));
      });

      var new_field = field.replace('Set', 's');
      if (new_field != field) {
        obj[new_field] = flat;
        delete obj[field];
      } else {
        obj[field] = flat;
      }
    }
  });

  return obj;
}

export function convert_id (obj) {
  if (obj.id) {
    obj.id = parseInt(atob(obj.id).split(":")[1]);
  }

  return obj;
}
