import { Plugins } from '@capacitor/core';

var plugins = {};

if (window.plugins) {
  plugins = Object.assign({}, Plugins, window.plugins);
} else {
  plugins = Object.assign({}, Plugins);
}

export default plugins;
