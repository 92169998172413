<template>
<div id="guide-page">
  <v-card>
    <v-card-title primary-title>
      <h2><em>Guides, Coming Soon</em></h2>
    </v-card-title>
  </v-card>
</div>
</template>
<script>
export default {

};
</script>