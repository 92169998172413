<template>
<div>
  <v-card class="weekly">
    <v-card-title primary-title>
      <h2>Week Forecast</h2>
    </v-card-title>
    <div class="days">
      <div v-for="f in forecast" :key="f.dayname" class="day">
        <div>
          <div class="main">
            <div class="icon">
              <img :src="icon(f.details.icon)" :style="{width: '40px', height: '40px'}">
            </div>
            <span class="name">{{ f.dayname }}</span>
            <span class="summary">{{ f.details.desc }}</span>
            <span>
              {{ dvalue(f.temperatureMin) }}&nbsp;-&nbsp;{{ dvalue(f.temperatureMax) }}&deg;F
            </span>
          </div>
          <div class="line hidden-xs-only" v-if="show_trend(f)">
            <trends :current="f.hourly" :w="420" :h="70" partial-labels></trends>
          </div>
          <div>
            <span class="small" v-if="f.humidity">
              Humidity: {{ dvalue(f.humidityMin) }}&nbsp;-&nbsp;{{ dvalue(f.humidityMax) }}%
            </span>
            <span class="small" v-if="f.windSpeed">
              <span>
                Wind: {{ dvalue(f.windSpeedMin) }}&nbsp;-&nbsp;{{ dvalue(f.windSpeedMax) }}mph
              </span>
            </span>
            <span class="small" v-if="f.precipitationProbability">
              Precip: {{ f.precipitationProbability }}%
            </span>
            <!-- <span class="small" v-if="f.precipitation_accumulation && f.precipitation_accumulation.value">
              Rain: {{ rnd2(f.precipitation_accumulation.value) }} in
            </span> -->
          </div>
        </div>
        <div class="line line-mobile hidden-sm-and-up" v-if="show_trend(f)">
          <trends :current="f.hourly" :w="300" :h="50" partial-labels></trends>
        </div>
        <v-divider></v-divider>
      </div>
    </div>
  </v-card>
</div>
</template>
<script>
import {icon_class, wind_direction, wind_speed, temp} from './calcs/weather.js';
import trends from './widgets/trends.vue';

export default {
  props: ['forecast'],
  methods: {
    dvalue(v) {
      return Math.round(v);
      var point = 0;
      f[v].forEach((d) => {
        if (d[key] !== undefined) {
          point = d[key].value;
        }
      });

      return Math.round(point);
    },
    has_rain(f) {
      if (f.hourly) {
        let count = 0;

        for (let i in f.hourly) {
          if (f.hourly[i].rain && f.hourly[i].rain['1h']) {
            var amt = f.hourly[i].rain['1h'] / 25.4;
            if (amt >= 0.005) {
              return true;
            }
          }
        }
      }
      return false;
    },
    show_trend(f) {
      if(f.hourly) {
        if (f.hourly.length == 24 || f.dayname == 'today') {
          return true;
        }
      }

      return false;
    },
    wind_speed(f) {
      if (f && f.windSpeed) {
        return wind_speed(f.windSpeed)
      }
    },
    wind_direction(d) {
      return wind_direction(d)
    },
    icon_class(w) {
      return icon_class(w);
    },
    icon(ico) {
      var postfix = '';
      var conditions = ['clear', 'mostly_clear', 'partly_cloudy'];
      if (conditions.indexOf(ico) > -1) {
        postfix = '_day';
      }

      var staticd = STATIC_DIR;
      return `${staticd}climacell-icons/${ico}${postfix}.svg`;
    },
    rain_fall(value) {
      return Math.round(value * 100);
    },
    rnd2(value) {
      value = value.toFixed(2);

      if (value == '0.00') {
        value = '<0.01';
      }

      return value;
    },
    to_in(value) {
      value = value / 25.4;
      value = value.toFixed(2);

      if (value == '0.00') {
        value = '<0.01';
      }

      return value;
    }
  },
  components: {
    trends
  }
};
</script>
<style lang="less">
@import "media.less";

.weekly {
  .days {
    max-width: 800px;
    margin: 0 auto;
  }

  .summary {
    text-transform: lowercase;
  }

  .trend {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;

    .label {
      font-size: 80%;
      padding-right: 7px;
    }

    .data {
      font-size: 80%;
    }

    svg {
      height: 45px;

      text {
        font-size: 9px !important;
      }
    }

    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  }

  .day > div {
    margin: 20px;
    display: flex;


    .name {
      font-weight: bold;
      font-size: 1.3rem;
      white-space: nowrap;
    }

    .main {
      font-size: 1.1rem;
      flex: 1;
      max-width: 150px;

      @media @for-phone {
        max-width: none;
      }
    }

    i {
      font-size: 56px;
      line-height: 56px;

      @media @for-tablet {
        font-size: 42px;
        line-height: 42px;
      }
    }

    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 10px;

      &.icon {

      }

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        min-width: 120px;
        padding-right: 0;
        flex: 1;
        align-items: flex-end;
      }
    }
  }

}
</style>
