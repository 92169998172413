<template>
<div class="assistant">
  <div>
    <p>
      Access all the current lake information on your Amazon Alexa.
    </p>
  </div>
  <ul>
    <li>
      <strong>Hear current conditions:</strong>
      <br>
      <span class="indent">
        Alexa, open Canyon Lake Guide for <em>current conditions</em>
      </span>
    </li>
    <li>
      <strong>Hear the weather forecast:</strong>
      <br>
      <span class="indent">
        Alexa, open Canyon Lake Guide for <em>the forecast</em>
      </span>
    </li>
    <li>
      <strong>Hear the water conditions:</strong>
      <br>
      <span class="indent">
        Alexa, open Canyon Lake Guide for <em>water conditions</em>
      </span>
    </li>
    <li>
      <strong>Hear the fishing report:</strong>
      <br>
      <span class="indent">
        Alexa, open Canyon Lake Guide for <em>fishing</em>
      </span>
    </li>
    <li>
      <strong>Hear the ramp closures:</strong>
      <br>
      <span class="indent">
        Alexa, open Canyon Lake Guide for <em>ramp closures</em>
      </span>
    </li>
  </ul>
</div>
</template>
<script>
export default {
  data() {
    return {
      title: 'Amazon Alexa'
    };
  }
};
</script>
<style lang="less">

</style>