import Vue from 'vue';
import VueRouter from "vue-router";
import Vuetify from 'vuetify';
import VueGtag from "vue-gtag";

import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

import mainapp from './main-app';
import router from "./routes";
import get_latest from './data.js';

import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css';
import 'typeface-roboto/index.css';

if (!DEBUG) {
  Sentry.init({
    dsn: 'https://ade7de1834dd4496906339b2114b7289@sentry.io/1325463',
    integrations: [
      new Integrations.Vue({
        Vue,
        attachProps: true,
      }),
    ],
  });
}

Vue.use(Vuetify);
Vue.use(VueRouter);

var light = {
  primary: '#00BCD4',
  secondary: '#0097A7',
  accent: '#FF9800',
  error: '#F44336',
  warning: '#FBC02D',
  info: '#0097A7',
  success: '#4CAF50'
};
var vui = new Vuetify({theme: {themes: {light}}});


if (!DEBUG && PLATFORM != 'native') {
  Vue.use(VueGtag, {config: {id: 'UA-4108685-24'}}, router);
}

//if (DEBUG) {
  Vue.config.devtools = true;
//}

Vue.prototype.$fetch_data = () => {
  return new Promise((resolve, reject) => {
    get_latest()
      .then((response) => {
        DATA = response.latestData;
        DATA.boatRamps = response.boatRamps;
        resolve(DATA);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

var app = new Vue({
  el: '#app',
  router: router,
  vuetify: vui,
  components: {mainapp},
  mounted: function() {
    this.$nextTick(() => {
      this.unflash();
    });
  },
  methods: {
    unflash() {
      document.querySelector("#app").style.display = 'block';
      document.querySelector("#flash").style.display = 'none';
    }
  }
});
