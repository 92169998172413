import graphql from 'graphql.js';
import lscache from 'lscache';

var API = "/data/";
if (PLATFORM == 'native') {
  API = "https://www.canyonlake.app/data/"
}

console.log(API);
var DataGraph = graphql(API);


function cached_page(fname, target) {
  function wrapper () {
    var args = [fname];
    for (var i=0; i < arguments.length; i++) {
      args.push(JSON.stringify(arguments[i]));
    }

    var ckey = args.join(':');
    var cached = lscache.get(ckey);
    if (cached) {
      return result_promise(ckey, cached);
    }

    return set_cache_result(ckey, target.apply(null, arguments));
  }

  return wrapper;
}

function cleanup_cache () {
  lscache.flushExpired();
}

function result_promise (ckey, result) {
  console.log('Cached result', ckey);
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      resolve(result);
    });
  });
}

function set_cache_result (ckey, promise) {
  return new Promise(function (resolve, reject) {
    promise
      .then(function (result) {
        lscache.set(ckey, result, 5);
        setTimeout(cleanup_cache);
        resolve(result);
      })
      .catch(function (e) {
        reject(e);
      });
  });
}

export default function get_latest () {
  return DataGraph.query(`query {
    latestData{
      lakeTemp
      lakeLevel
      riverFlow
      riverFlowUpper
      riverTemp
      climacellv4
      climacellv4Hourly
      climacellv4Daily
      date
      release
      fishing
      bait
    }
    boatRamps{
      edges{
        node{
          name
          info
          closed
          closedLow
          closedHigh
        }
      }
    }
  }`)();
}

export function double_digit(value){
  return value.toLocaleString(
    'en-US', {minimumIntegerDigits: 2, useGrouping:false});
}



export var signup = DataGraph.mutate(`mutation (
    $name: String!,
    $email: String!
    ) {
    nlSignup(
      input: {
        name: $name
        email: $email
    	}
    ){
      errors{
        field
      }
    }
  }`);
