<script>
import currentWeather from './current-weather.vue';
import LoadMixin from './load-mixin.js';
import NewsletterDialog from './widgets/newsletter.vue';

export function water_temp_state (temp) {
  if (!temp) {
    return {};
  }

  if (temp < 60) {
    return {value: 'frigid', color: 'primary'};
  } else if (temp <= 73) {
    return {value: 'cold', color: 'primary'};
  } else if (temp <= 76) {
    return {value: 'cool', color: 'success'};
  } else if (temp <= 82) {
    return {value: 'perfect', color: 'success'};
  } else if (temp <= 88) {
    return {value: 'warm', color: 'success'};
  } else if (temp <= 103) {
    return {value: 'hot', color: 'warning'};
  } else if (temp <= 113) {
    return {value: 'hot tub', color: 'error'};
  }

  return {value: 'extemely hot', color: 'error'};
}

export function lake_level_state (level) {
  const normal = 909;
  const high = normal + 1.5;
  const really_high = normal + 6;
  const low = normal - 1.5;
  const really_low = normal - 11;

  if (level <= high && level >= low) {
    return 'normal';
  } else if (level < really_low) {
    return 'extremely low';
  } else if (level < low) {
    return 'below avg';
  } else if (level < really_high) {
    return 'above avg';
  }

  return 'extremely high';
}

export function tubing_state (flow) {
  if (!flow) {
    return {};
  }

  if (flow < 80) {
    return {value: 'low and slow', color: 'warning'};
  } else if (flow <= 500) {
    return {value: 'all activities available', color: 'success'};
  } else if (flow <= 750) {
    return {value: 'use caution, life jackets req. under 8', color: 'warning'};
  } else if (flow <= 1000) {
    return {value: 'extreme caution, tubing questionable, life jackets req.', color: 'error'};
  } else if (flow <= 3000) {
    return {value: 'no tubing, raft and kayak only', color: 'error'};
  }

  return {value: 'guided trips only', color: 'error'};
}

export default {
  mixins: [LoadMixin],
  data: function () {
    return {
      current: Object.assign({}, DATA)
    };
  },
  mounted() {

  },
  computed: {
    river_temp() {
      if (this.current.riverTemp) {
        return Math.round(this.current.riverTemp);
      }
    },
    lake_temp_state() {
      return water_temp_state(this.current.lakeTemp);
    },
    river_temp_state() {
      return water_temp_state(this.current.riverTemp);
    },
    lake_level_state() {
      return lake_level_state(this.current.lakeLevel);
    },
    tubing_state() {
      return tubing_state(this.current.riverFlow);
    },
    tubing_state_upper() {
      return tubing_state(this.current.riverFlowUpper);
    }
  },
  methods: {
    refresh() {
      this.$fetch_data()
        .then(() => {
          this.current = Object.assign({}, DATA);
        })
        .catch((error) => {
          alert('Error fetching data');
        });
    },
    color_class(obj) {
      return obj.color + '--text';
    }
  },
  components: {
    currentWeather,
    NewsletterDialog
  }
};
</script>
<template>
<div>
  <v-card class="current">
    <v-card-title primary-title class="flex-row align-center">
      <div>
        <h2>Current Conditions</h2>
        <div class="updated" v-if="current && current.date">
          Updated @ {{ current.date }}
        </div>
      </div>
      <v-spacer></v-spacer>
      <div>
        <v-btn icon color="primary" @click="refresh()">
          <v-icon>mdi-cloud-refresh</v-icon>
        </v-btn>
      </div>
    </v-card-title>
    <div>
      <current-weather :radar="true" :current="current.climacellv4" :size="64" v-if="current.climacellv4"></current-weather>
      <table>
        <tr>
          <td class="label">
            Est Lake Temp:
          </td>
          <td class="data">
            {{ current.lakeTemp }}&nbsp;&deg;F
          </td>
          <td class="label">
            Swim Conditions:
          </td>
          <td class="data">
            <span :class="color_class(lake_temp_state)">
              {{ lake_temp_state.value }}
            </span>
          </td>
        </tr>
        <tr>
          <td class="label">
            Lake Level:
          </td>
          <td class="data">
            {{ lake_level_state }} - {{ current.lakeLevel }}&nbsp;ft
          </td>
          <td>

          </td>
          <td></td>
        </tr>
        <tr>
          <td class="label">
            Avg River Temp:
          </td>
          <td class="data">
            {{ river_temp }}&nbsp;&deg;F
          </td>
          <td class="label">
            Swim Conditions:
          </td>
          <td class="data">
            <span :class="color_class(river_temp_state)">
              {{ river_temp_state.value }}
            </span>
          </td>
        </tr>
        <tr>
          <td class="label">
            River Flow (Spring Branch)
          </td>
          <td class="data">
            {{ current.riverFlowUpper }}&nbsp;ft<sup>3</sup>/s
          </td>
          <td class="label">
            Recreational Status:
          </td>
          <td class="data">
            <span :class="color_class(tubing_state_upper)">
              {{ tubing_state_upper.value }}
            </span>
          </td>
        </tr>
        <tr>
          <td class="label">
            River Flow (Sattler)
          </td>
          <td class="data">
            {{ current.riverFlow }}&nbsp;ft<sup>3</sup>/s
          </td>
          <td class="label">
            Recreational Status:
          </td>
          <td class="data">
            <span :class="color_class(tubing_state)">
              {{ tubing_state.value }}
            </span>
          </td>
        </tr>
      </table>
      <ul>
        <li>
          <div class="label">
            Est Lake Temp:
          </div>
          <div>
            {{ current.lakeTemp }}&nbsp;&deg;F
          </div>
          <div class="label">
            Swim Conditions:
          </div>
          <div>
            <span :class="color_class(lake_temp_state)">
              {{ lake_temp_state.value }}
            </span>
          </div>
        </li>
        <li>
          <div class="label">
            Lake Level:
          </div>
          <div>
            {{ lake_level_state }} - {{ current.lakeLevel }}&nbsp;ft
          </div>
        </li>
        <li>
          <div class="label">
            Avg River Temp:
          </div>
          <div>
            {{ river_temp }}&nbsp;&deg;F
          </div>
          <div class="label">
            Swim Conditions:
          </div>
          <div>
            <span :class="color_class(river_temp_state)">
              {{ river_temp_state.value }}
            </span>
          </div>
        </li>
        <li>
          <div class="label">
            River Flow: <em class="small">(Spring Branch)</em>
          </div>
          <div>
            {{ current.riverFlowUpper }}&nbsp;ft<sup>3</sup>/s
          </div>
          <div class="label">
            Recreational Status:
          </div>
          <div>
            <span :class="color_class(tubing_state_upper)">
              {{ tubing_state_upper.value }}
            </span>
          </div>
        </li>
        <li>
          <div class="label">
            River Flow: <em class="small">(Sattler)</em>
          </div>
          <div>
            {{ current.riverFlow }}&nbsp;ft<sup>3</sup>/s
          </div>
          <div class="label">
            Recreational Status:
          </div>
          <div>
            <span :class="color_class(tubing_state)">
              {{ tubing_state.value }}
            </span>
          </div>
        </li>
      </ul>
    </div>
    <v-divider></v-divider>
    <div class="more">
      <h3>Sponsored By Cactus Camper</h3>
      <div>
          Stay in Canyon Lake @<br class="small-break">
          <a href="https://www.airbnb.com/rooms/49060931?utm_source
=cla" target="_blank">Cactus Camper</a><br class="small-break"> or <br class="small-break">
          <a href="https://www.airbnb.com/rooms/641901689568420328?utm_source
=cla" target="_blank">Llama Lodge</a>
          <br><br>
      </div>
    <v-divider></v-divider>
    <newsletter-dialog></newsletter-dialog>
    <v-divider></v-divider>
    <div class="more">
      <h3>Explore CanyonLake.app</h3>
      <div>
        <v-btn small color="primary" to="/forecast">
          <v-icon>mdi-white-balance-sunny</v-icon>&nbsp;Forecast
        </v-btn>
        <v-btn small color="primary" to="/forecast/maps">
          <v-icon>mdi-satellite</v-icon>&nbsp;Weather Maps
        </v-btn>
        <v-btn small color="primary" to="/fishing">
          <v-icon>mdi-waves</v-icon>&nbsp;Fishing & Boating Report
        </v-btn>
        <v-btn small color="primary" to="/events">
          <v-icon>mdi-calendar</v-icon>&nbsp;Events
        </v-btn>
        <v-btn small color="primary" to="/help">
          <v-icon>mdi-help-circle-outline</v-icon>&nbsp;Help
        </v-btn>
      </div>
    </div>
  </v-card>
</div>
</template>
<style lang="less">
@import "media.less";

.release {
  text-align: center;
  padding-bottom: 15px;
}

.updated {
  font-size: 80%;
  padding: 0;
  margin-top: -5px;
  margin-left: 5px;
}

.current {
  font-size: 1.2rem;

  canvas {
    width: 64px;
    height: 64px;
  }

  em.small {
    font-size: 75%;
  }

  .loading {
    text-align: center;
    padding: 20px 0;
  }

  .v-card__title {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .label {
    font-weight: bold;
    white-space: nowrap;
  }

  table {
    border: 0;
    border-collapse: collapse;
    width: 100%;

    @media @for-phone {
      display: none;
    }

    td {
      padding: 0 0 20px 0;
      vertical-align: top;
    }

    .data {
      padding-right: 20px;
    }

    .label {
      padding-left: 20px;
      padding-right: 10px;
    }
  }

  ul {
    display: none;
    list-style-type: none;
    margin: 0;
    padding: 0;

    @media @for-phone {
      display: block;
      padding-left: 0 !important;
    }

    li {
      display: flex;
      flex-wrap: wrap;
      padding: 0 20px 20px 20px;

      @media @for-phone {
        padding: 0 12px 15px 12px;
      }
    }

    div {
      &.label {
        flex: 1;
        min-width: 50%;
        padding-right: 20px;
      }
    }
  }

  .more {
    padding: 16px;

    > div {
      max-width: 600px;
      margin: 0 auto;
      text-align: center;

      .v-btn {
        margin-bottom: 5px;
        margin-top: 5px;
      }
    }
  }
}
</style>
