<template>
<v-card>
  <v-card-title primary-title>
    <h1>Help and FAQ</h1>
  </v-card-title>
  <div class="assistant" style="padding-right: 15px;">
    <ul>
      <li>
        <strong>Contact Us:</strong>
        <br>
        <span class="indent">
          E-Mail: <a href="mailto:info@canyonlake.app">info@canyonlake.app</a>
        </span>
      </li>
      <li>
        <strong>iOS and Android App</strong>
        <br>
        <span class="indent">
          <a href="https://apps.apple.com/us/app/canyonlake-app/id1510001453" target="_blank">
            <img src="../icons/appledl.png" alt="Download on the App Store" style="height: 48px;">
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.canyonlake.app" target="_blank">
            <img src="../icons/googledl.png" alt="Download on Google Play"  style="height: 48px;">
          </a>
        </span>
      </li>
      <li>
        <strong>Install Chrome</strong>
        <br>
        <span class="indent">
          Click "Install" in the address bar to download as an Apps.
        </span>
      </li>
      <li>
        <strong>Install Web App on iOS</strong>
        <br>
        <span class="indent">
          Press the share button and then "add to home screen".
        </span>
      </li>
      <li>
        <strong>Install Web App on Android</strong>
        <br>
        <span class="indent">
          Press the menu button and then "add to home screen". You will also be
          prompted automatically to install the app if you visit the site
          multiple times.
        </span>
      </li>
    </ul>
  </div>
  <br>
</v-card>
</template>
<script>
export default {
  data() {
    return {
      title: 'Help and FAQ'
    };
  }
};
</script>
