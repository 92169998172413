<template>
<div class="events-page">
  <v-card>
    <v-card-title primary-title>
      <h2>
        <span>Area Events</span>
      </h2>
    </v-card-title>
    <v-card-text>
      <div>
        <h3>Outdoor Events:</h3>
        <ul>
          <li>
            <a href="https://tpwd.texas.gov/state-parks/guadalupe-river/park_events" target="_blank">
              Guadalupe River State Park
            </a>
          </li>
          <li>
            <a href="https://tpwd.texas.gov/state-parks/blanco/park_events" target="_blank">
              Blanco State Park
            </a>
          </li>
          <li>
            <a href="https://tejasrodeo.com/" target="_blank">
              Tejas Rodeo
            </a> - Saturday Nights
          </li>
          <li>
            <a href="http://thursdaynighters.com/calendar/">Thursday Nighters</a>
             - weekly fishing, Thurs nights in the summer
          </li>
        </ul>
      </div>
      <div>
        <h3>Music:</h3>
        <ul>
          <li>
            <a href="https://www.whitewaterrocks.com/" target="_blank">
              Whitewater Amphitheater
            </a>
          </li>
          <li>
            <a href="https://brauntex.org/theater-upcoming-events/" target="_blank">
              Brauntex Theatre
            </a>
          </li>
          <li>
            <a href="https://www.facebook.com/MavensCanyonLake/events" target="_blank">
              Maven's
            </a> - Fridays and Saturdays
          </li>
          <li>
            <a href="https://www.brookshirebrothers.com/blog-entry/canyon-lake-party-patio" target="_blank">
              Brookshire Brothers Patio
            </a>
          </li>
        </ul>
      </div>
      <div>
        <h3>Shopping:</h3>
        <ul>
          <li>
            <a href="https://www.nbfarmersmarket.com/" target="_blank">
              New Braunfels Farmers Market
            </a> - Saturdays
          </li>
          <li>
            <a href="http://gruenemarketdays.com/schedule.html" target="_blank">
              Gruene Market Days
            </a> - 3rd full weekend of each month
          </li>
        </ul>
      </div>
      <div>
        <h3>Misc:</h3>
        <ul>
          <li>
            <a href="https://www.playinnewbraunfels.com/events/" target="_blank">
              New Braunfels Events
            </a>
          </li>
        </ul>
      </div>
      <newsletter-dialog></newsletter-dialog>
    </v-card-text>
  </v-card>
</div>
</template>
<script>
import {double_digit} from '../data.js';
import {extract_nodes, slugify, createQueryParams} from '../utils/transformers.js';
import NewsletterDialog from '../widgets/newsletter.vue';

export default {
  components: {
    NewsletterDialog
  },
  data() {
    return {};
  },
  watch: {},
  computed: {

  },
  mounted() {

  },
  methods: {}
}
</script>
<style lang="less">
@import "../colors.less";
@import "../media.less";

.events-page {
  .v-card__text{
    margin-top: 0;
    padding-top: 0;

    @media @for-phone {
      padding-right: 3px;
      padding-left: 3px;
    }
  }

  @media @for-phone {
    .v-list__tile, .v-subheader {
      padding-right: 12px;
      padding-left: 12px;
    }
  }

  .v-list {
    max-width: 800px;
    margin: 0 auto;
    align-items: flex-start;
  }

  .v-card__title {
    padding-bottom: 0;
    align-items: center;

    .page {
      text-align: right;
      flex: 1;
    }
  }

  .v-list-item__subtitle {
    font-size: 14px;
  }

  .cancelled {
    color: @errorColor;
    margin-right: 7px;
    display: flex;
    align-items: center;
  }

  .v-list-item__title {
    strong {
      font-size: 110%;
    }
  }

  .v-subheader {
    font-size: 1.3rem;
  }

  .v-list-item__action {
    justify-content: center;
    align-items: flex-start;
    padding-top: 0;
    padding-bottom: 0;
    flex-direction: row;
    margin-left: 5px !important;

    a {
      margin: 0 2px;
    }
  }

  .v-btn--icon.v-size--small .v-icon {
    height: 22px;
    font-size: 22px;
    width: 22px;
  }

  .controls {
    display: flex;
    flex-wrap: wrap;
    padding: 0 20px 10px 20px;
    align-items: baseline;

    > div {
      font-size: 80%;
      margin-right: 25px;
    }

    .v-messages {
      display: none;
    }

    .v-input__slot {
      margin-bottom: 0;
    }

    .btn {
      text-align: right;
      flex: 1;
      margin-top: 10px;

      @media @for-tablet {
        width: 100%;
        flex: none;
      }
    }
  }

  @media @for-desktop {
    .v-list__tile__action {
      flex-direction: row;

      a {
        margin: 1px 2px;
      }
    }

    .v-list__tile {
      margin-bottom: 30px;
    }
  }

  .cats {
    font-size: 80%;
    text-transform: lowercase;
  }

  .pagination {
    display: flex;
    align-items: center;

    > div {
      flex: 1;
    }

    .current {
      text-align: center;
    }

    .next {
      text-align: right;
    }
  }
}
</style>
