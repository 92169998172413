<template>
<div class="assistant">
  <div>
    <p>
      Access all the current lake information on your Google Assistant.
    </p>
  </div>
  <ul>
    <li>
      <strong>Hear current conditions:</strong>
      <br>
      <span class="indent">
        OK Google talk to the Canyon Lake Guide
      </span>
    </li>
    <li>
      <strong>Hear the weather forecast:</strong>
      <br>
      <span class="indent">
        OK Google talk to the Canyon Lake Guide <em>about the forecast</em>
      </span>
    </li>
    <li>
      <strong>Hear the water conditions:</strong>
      <br>
      <span class="indent">
        OK Google talk to the Canyon Lake Guide <em>about the water conditions</em>
      </span>
    </li>
    <li>
      <strong>Hear the fishing report:</strong>
      <br>
      <span class="indent">
        OK Google talk to the Canyon Lake Guide <em>about fishing</em>
      </span>
    </li>
    <li>
      <strong>Hear the ramp closures:</strong>
      <br>
      <span class="indent">
        OK Google talk to the Canyon Lake Guide <em>about ramp closures</em>
      </span>
    </li>
  </ul>
</div>
</template>
<script>
export default {
  data() {
    return {
      title: 'Google Assistant'
    };
  }
};
</script>
<style lang="less">

</style>