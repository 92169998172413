<template>
<div class="newsletter" v-if="!hide_mail">
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on }">
      <div style="padding: 0 10px;">
        <strong>Plan your weekend with</strong>
        <br>
        <strong>the CanyonLake.app Weekend Newsletter</strong>
        <br>
        <v-btn color="accent" v-on="on">
          <v-icon>mdi-email</v-icon>&nbsp;Sign Up
        </v-btn>
        <br>
        <v-btn text small color="primary" @click="hide()">
          Hide
        </v-btn>
        <br>
        <br>
      </div>
    </template>
    <v-card class="newsletter-dialog" v-if="!verification">
      <v-form v-model="valid" ref="form" lazy-validation @submit.prevent="handleSubmit">
        <v-card-title class="headline grey lighten-2" primary-title>
          Weekend Newsletter
        </v-card-title>
        <v-card-text>
          <br>
          <p>
            Sign up and every Friday morning we'll send you the current lake and
            river conditions, fishing and boating conditions, and featured events
            so you can plan your weekend.
          </p>
          <v-text-field label="Name" :rules="nameRules" required v-model="name"></v-text-field>
          <v-text-field label="E-mail" :rules="emailRules" required v-model="email"></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" type="submit">
            Sign Up
          </v-btn>
          <v-btn @click="close()">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
    <v-card class="newsletter-dialog" v-if="verification">
      <v-card-title class="headline grey lighten-2" primary-title>
        Weekend Newsletter
      </v-card-title>
      <v-card-text>
        <br><br>
        <strong class="verification">Check your e-mail to verify your address.</strong>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="close()">
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>
<script>
import {signup} from '../data.js';

export default {
  data() {
    return {
      dialog: false,
      valid: false,
      verification: false,
      hide_mail: HIDE_MAIL,
      name: "",
      nameRules: [
        v => !!v || 'Name is required',
        v => v.length <= 75 || 'Name must be less than 75 characters'
      ],
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid',
        v => v.length <= 150 || 'Name must be less than 150 characters'
      ]
    };
  },
  mounted() {
    this.init();
  },
  watch: {
    '$route': 'init'
  },
  methods: {
    init() {
      this.verification = false;

      if (this.$route.query && this.$route.query.newsletter) {
        this.dialog = true;
      }
    },
    close() {
      this.dialog = false;
      this.verification = false;
    },
    hide() {
      this.hide_mail = "true";
      HIDE_MAIL = "true";
      localStorage.mail = "true";
    },
    handleSubmit() {
      if (this.$refs.form.validate()) {
        signup({name: this.name, email: this.email})
          .then((result) => {
            this.verification = true;
            this.hide_mail = "true";
            HIDE_MAIL = "true";
            localStorage.mail = "true";
          })
          .catch((e) => {
            alert('Error signing up, please try again.');
            console.error(e);
          })
      }
    }
  }
};
</script>
<style lang="less">
.newsletter {
  text-align: center;
  margin: 20px 0 0 0;
}

.newsletter-dialog {
  margin-bottom: 0;

  .verification {
    font-size: 120%;
  }
}
</style>
