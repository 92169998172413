<template>
<v-card>
  <v-card-title primary-title>
    <h1>Privacy Policy</h1>
  </v-card-title>
  <div class="assistant">
    <p style="padding: 0 20px; max-width: 650px;">
      We do not store any user information unless you sign up for our newsletter.
      That information is only used to send out our weekly newsletter from which
      you can unsubscribe at anytime. No personal information is sent to
      third parties except for non-identifiable information for Google Analytics.
    </p>
  </div>
  <br>
</v-card>
</template>
<script>
export default {
  data() {
    return {
      title: 'Privacy Policy'
    };
  }
};
</script>
