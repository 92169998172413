<template>
<div class="current-weather">
  <div class="main">
    <img :src="icon" :style="{width: size + 'px', height: size + 'px'}">
    <span>{{ desc }}</span>
    <div class="radar-link" v-if="radar">
      <v-btn text color="primary" :to="{name: 'weather-maps'}">
        <v-icon>mdi-satellite</v-icon>&nbsp;Weather Maps
      </v-btn>
    </div>
  </div>
  <div>
    <span>{{ temp }}&nbsp;&deg;F</span>
    <span class="small" v-if="humidity">Humidity: {{ humidity }}%</span>
    <span class="small" v-if="dew_point">Dew Point: {{ dew_point }}&deg;F</span>
    <span class="small">
      Wind: {{ wind_speed }} mph {{ wind_direction }}
    </span>
    <span class="small" v-if="current.precipitationIntensity || current.precipitationProbability">
      Precip: {{ rain_fall }}% : {{ intensity }} in/hr
    </span>
  </div>
</div>
</template>
<script>
import WeatherMixin from './calcs/weather.js';

export default {
  props: ['current', 'radar', 'size'],
  mixins: [WeatherMixin],
  computed: {
    desc() {
      return this.current.details.desc;
    },
    icon() {
      var postfix = '';
      var conditions = ['clear', 'mostly_clear', 'partly_cloudy'];
      if (conditions.indexOf(this.current.details.icon) > -1) {
        postfix = this.current.details.postfix;
      }

      var staticd = STATIC_DIR;
      return `${staticd}climacell-icons/${this.current.details.icon}${postfix}.svg`;
    },
    rain_fall() {
      return Math.round(this.current.precipitationProbability);
    },
    intensity() {
      var value = this.current.precipitationIntensity;
      value = value.toFixed(2);

      if (value == '0.00') {
        value = '<0.01';
      }

      return value;
    }
  }
};
</script>
<style lang="less">
.current-weather {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0 20px 0;
  font-size: 2.0rem;

  .radar-link .v-btn {
    font-size: 13px;

    .v-icon {
      font-size: 28px;
    }
  }

  .main {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-transform: lowercase;
  }

  > div {
    padding: 0 20px;
    text-align: center;

    span {
      display: block;
    }

    .small {
      font-size: 1.0rem;
    }
  }
}
</style>
