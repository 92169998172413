export default {
  methods: {
    load() {
      this.current = Object.assign({}, DATA);

      if (!this.current.release) {
        setTimeout(() => {
          this.load();
        }, 100);
      } else {
        setTimeout(() => {
          this.load();
        }, 1000 * 5);
      }
    }
  },
  mounted: function() {
    this.load();
  }
};
