<template>
<div id="weather-maps-page">
  <v-card>
    <v-card-title primary-title>
      <h2>Current Weather Maps</h2>
      <router-link to="/" v-if="back_home">&laquo; Current Conditions</router-link>
      <router-link to="/forecast" v-else>&laquo; Forecast</router-link>
    </v-card-title>
    <v-card-text>
      <div id="wmap">
        <iframe
          width="100%"
          height="500"
          src="https://embed.windy.com/embed2.html?lat=29.838&lon=-98.234&detailLat=29.319&detailLon=-98.244&width=650&height=450&zoom=9&level=surface&overlay=radar&product=radar&menu=&message=true&marker=&calendar=now&pressure=&type=map&location=coordinates&detail=&metricWind=mph&metricTemp=%C2%B0F&radarRange=-1"
          frameborder="0"
        ></iframe>
      </div>
    </v-card-text>
  </v-card>
</div>
</template>
<script>
export default {
  data() {
    return {
      title: "Weather Maps",
      back_home: true,
      map: 'radar',
      maps: [
        {name: 'Precipitation Radar', value: 'radar'},
        {name: 'Precipitation Forecast', value: 'precipitation_rate'},
        {name: 'Temperature', value: 'temperature'},
        {name: 'Feels Like Temperature', value: 'apparent_temperature'},
        {name: 'Cloud Cover', value: 'cloud_cover'},
        {name: 'Wind Speed', value: 'wind_speed'},
        {name: 'Wind Gust', value: 'wind_gust'},
        {name: 'Dew Point', value: 'dew_point'},
        {name: 'UV Index', value: 'uv_index'},
        {name: 'Pressure', value: 'sea_level_pressure'},
        {name: 'Ozone', value: 'ozone'},
        {name: 'Emoji', value: 'emoji'}
      ]
    };
  },
  computed: {
    mapurl() {
      var funits = ['temperature', 'apparent_temperature', 'dew_point'];
      var mphunits = ['wind_speed', 'wind_gust'];
      var hgunits = ['sea_level_pressure'];
      var duunits = ['ozone'];
      var inunits = ['precipitation_rate'];
      var url = 'https://maps.darksky.net/@radar,29.864,-98.244,10?domain=https%3A%2F%2Fwww.canyonlake.app%2Fforecast%2Fmaps&auth=1559858838_9f3076ff1020f61b1b21a81c345ca936&embed=true&timeControl=true&fieldControl=false&defaultField=';
      url += this.map;

      if (funits.indexOf(this.map) > -1) {
        url += '&defaultUnits=_f';
      } else if (mphunits.indexOf(this.map) > -1) {
        url += '&defaultUnits=_mph';
      } else if (hgunits.indexOf(this.map) > -1) {
        url += '&defaultUnits=_inhg';
      } else if (duunits.indexOf(this.map) > -1) {
        url += '&defaultUnits=_du';
      }  else if (inunits.indexOf(this.map) > -1) {
        url += '&defaultUnits=_inph';
      }

      return url;
    }
  },
  watch: {
    '$route' (to, from) {
      this.init();
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    recenter() {
      this.init();
    },
    map_changed() {
      var q = {map: this.map};
      if (this.$route.query && this.$route.query.back) {
        q.back = this.$route.query.back;
      }
      this.$router.push({name: 'weather-maps', query: q});
    },
    init() {
      if (this.$route.query && this.$route.query.back == 'forecast') {
        this.back_home = false;
      }

      if (this.$route.query && this.$route.query.map) {
        this.map = this.$route.query.map;
      }
    }
  }
};
</script>
<style lang="less">
#weather-maps-page {
  .v-card__title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .controls {
    > div {
      max-width: 420px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;

      .v-input {
        margin-right: 15px;
      }
    }
  }

  iframe {
    max-width: 720px;
    width: 100%;
    height: 500px;
    border: 0;
    margin: 0 auto;
    display: block;
  }
}
</style>
