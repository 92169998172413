<template>
<div class="trend">
  <div style="color: #D32F2F;">
    <div class="label">Temp</div>
    <div class="data">{{ Math.round(temp[0]) }}&nbsp;&deg;F</div>
    <v-sparkline color="#D32F2F" :value="temp" :width="w" :height="h" :line-width="1" :smooth="15" :padding="8"></v-sparkline>
    <div class="data">{{ Math.round(temp[temp.length - 1]) }}&nbsp;&deg;F</div>
  </div>
  <div style="color: #31a01c;">
    <div class="label">Humidity</div>
    <div class="data">{{ Math.round(humidity[0]) }}%</div>
    <v-sparkline color="#31a01c" :value="humidity" :width="w" :height="h" :line-width="1" :smooth="15" :padding="8"></v-sparkline>
    <div class="data">{{ Math.round(humidity[humidity.length - 1]) }}%</div>
  </div>
  <div style="color: #2196F3;" v-if="rain">
    <div class="label">Precip</div>
    <div class="data">{{ rain_100(rain[0]) }}&nbsp;in/hr</div>
    <v-sparkline color="#2196F3" :value="rain" :width="w" :height="h" :line-width="1" :smooth="15" :padding="8"></v-sparkline>
    <div class="data">{{ rain_100(rain[rain.length - 1]) }}&nbsp;in/hr</div>
  </div>
  <div style="color: #F57C00;">
    <div class="label">Wind</div>
    <div class="data">{{ Math.round(wind[0]) }}&nbsp;mph</div>
    <v-sparkline color="#F57C00" :value="wind" :label-size="lsize" :labels="labels" :width="w" :height="h" :line-width="1" :smooth="15" :padding="10"></v-sparkline>
    <div class="data">{{ Math.round(wind[wind.length - 1]) }}&nbsp;mph</div>
  </div>
</div>
</template>
<script>
export default {
  props: {
    current: {type: Array, required: true},
    partialLabels: {default: false, type: Boolean},
    w: {default: 300, type: Number},
    h: {default: 45, type: Number}
  },
  methods: {
    rain_100(value){
      // value = value / 100;
      return value.toFixed(2)
    }
  },
  computed: {
    lsize() {
      if (this.partialLabels){
        return 2;
      }

      return 7;
    },
    hide() {
      return false;
    },
    temp() {
      var ret = [];
      this.current.forEach(function (c) {
        ret.push(c.temperature);
      });

      return ret;
    },
    humidity() {
      var ret = [];
      this.current.forEach(function (c) {
        ret.push(Math.round(c.humidity));
      });

      return ret;
    },
    rain() {
      var ret = [];
      var has_rain = false;

      this.current.forEach(function (c) {
        ret.push(c.precipitationIntensity);

        if (c.precipitationIntensity > 0.01) {
          has_rain= true;
        }
      });

      if (has_rain) {
        return ret;
      }

      return undefined;
    },
    wind() {
      var ret = [];
      this.current.forEach(function (c) {
        if (c.windSpeed) {
          ret.push(c.windSpeed);
        } else {
          ret.push(0);
        }
      });

      return ret;
    },
    labels() {
      var ret = [];

      if (this.partialLabels) {
        this.current.forEach(function (c, i) {
          if (i % 4 == 0 && c.hour != '12 AM') {
            ret.push(c.hour);
          } else {
            ret.push(' ');
          }
        });
      } else {
        this.current.forEach(function (c) {
          ret.push(c.hour);
        });
      }

      return ret;
    }
  }
};
</script>
