import Vue from 'vue';
import VueRouter from "vue-router";

import current from './current.vue';
import forecast from './forecast.vue';
import events from './pages/events.vue';
import fishing from './pages/fishing.vue';
import guide from './pages/guide.vue';
import weatherMaps from './pages/weather-maps.vue';
import voice from './pages/voice.vue';
import help from './pages/help.vue';
import privacy from './pages/privacy.vue';

const NotFound = {
  template: "<div><h2>Page Not Found: 404</h2></div>"
};

export var Routes = [
  { path: "/forecast/maps", component: weatherMaps, name: 'weather-maps'},
  { path: "/forecast", component: forecast, name: 'forecast'},
  { path: "/fishing", component: fishing, name: 'fishing'},
  { path: "/voice", component: voice, name: 'voice'},
  { path: "/guide", component: guide, name: 'guide'},
  { path: "/help", component: help, name: 'help'},
  { path: "/privacy", component: privacy, name: 'privacy'},
  { path: "/events", component: events, name: 'events'},
  { path: "/", component: current, name: 'home'},
  { path: "*", component: NotFound, name: '404'}
];

var router = new VueRouter({
  mode: "history",
  routes: Routes,
  scrollBehavior: function(to, from, savedPosition) {
    return savedPosition || {x: 0, y: 0};
  }
});

router.routed = 0;
router.set_title = to => {
  var title;
  var page;

  if (typeof to == "string") {
    title = to;
  } else if (
    to.matched &&
    to.matched[0] &&
    to.matched[0].instances &&
    to.matched[0].instances.default
  ) {
    if (to.matched[0].instances.default.title) {
      title = to.matched[0].instances.default.title;
    }

    if (to.matched[0].instances.default.page) {
      page = to.matched[0].instances.default.page.current;
    }
  }

  if (page && page > 1) {
    title += ": Page " + page;
  }

  if (title) {
    title += " - CanyonLake.app";
  } else {
    title = "CanyonLake.app";
  }

  document.title = title;
};

router.afterEach((to, from) => {
  router.routed += 1;
  Vue.nextTick(() => {
    router.set_title(to);
  });
});


export default router;
