<template>
<v-card class="fishing">
  <v-card-title primary-title>
    <h2>Fishing &amp; Boating Report</h2>
  </v-card-title>
  <div class="ramps">
    <strong>Closed:</strong>
    <div>
      {{ closed_ramps }}
    </div>
  </div>
  <!-- <div class="ramps">
    <strong>Closed for the Season:</strong>
    <div>
      Ramps: 3, 4, 18
    </div>
  </div> -->
  <div class="report" v-if="fishing">
    <strong>Fishing for the Week of {{ fishing.dt }}</strong>
    <br>
    {{ fishing.report }}
  </div>
  <v-divider></v-divider>
  <newsletter-dialog></newsletter-dialog>
  <v-divider></v-divider>
  <div class="links">
    <strong class="main">Quick Links</strong>
    <div v-for="s in links" :key="s.source" class="source">
      <strong>{{ s.source }}</strong>
      <ul>
        <li v-for="l in s.links" :key="l.name">
          <a :href="l.link" target="_blank">{{ l.name }}</a>
        </li>
      </ul>
    </div>
  </div>
</v-card>
</template>
<script>
import LoadMixin from '../load-mixin.js';
import NewsletterDialog from '../widgets/newsletter.vue';

export default {
  mixins: [LoadMixin],
  components: {
    NewsletterDialog
  },
  data() {
    return {
      title: "Fishing & Boating Report",
      current: Object.assign({}, DATA),
      links: [
        {
          source: "Texas Parks",
          links: [
            {
              name: "Fishing Canyon Lake",
              link: "https://tpwd.texas.gov/fishboat/fish/recreational/lakes/canyon/"
            },
            {
              name: "Freshwater Bag and Length Limits",
              link: "https://tpwd.texas.gov/regulations/outdoor-annual/fishing/freshwater-fishing/bag-length-limits"
            },
            {
              name: "Canyon Lake Fish Attractor Project",
              link: "https://tpwd.texas.gov/fishboat/fish/recreational/lakes/canyon/structure.phtml"
            },
            {
              name: "Guadalupe River Trout Fishing",
              link: "https://tpwd.texas.gov/fishboat/fish/management/stocking/guadalupe.phtml"
            }
          ]
        },
        {
          source: "US Army Corp of Engineers",
          links: [
            {
              name: "Boat Ramp Map & Details",
              link: "http://www.swf-wc.usace.army.mil/canyon/Maps/Ramps_ShortFormat_2012_Full_WebVersion.pdf"
            },
            {
              name: "Boat Ramp Map & Attractors",
              link: "http://www.swf-wc.usace.army.mil/canyon/Maps/Ramps_LongFormat_2012_Full_WebVersion.pdf"
            }
          ]
        }
      ]
    };
  },
  computed: {
    fishing() {
      return this.current.fishing;
    },
    bait() {
      return this.current.bait;
    },
    closed_ramps() {
      if (this.current.boatRamps) {
        var ramps = [];

        this.current.boatRamps.edges.forEach((r) => {
          if (r.node.closed) {
            ramps.push(r.node.name);
          } else if (r.node.closedLow && r.node.closedHigh) {
            if (this.current.lakeLevel > r.node.closedHigh || this.current.lakeLevel < r.node.closedLow) {
              ramps.push(r.node.name);
            }
          }
        });

        return ramps.join(', ');
      }

      return '';
    }
  }
};
</script>
<style lang="less">
.fishing {
  .report {
    padding: 0 16px 16px 16px;
  }

  .ramps {
    padding: 0 16px 16px 16px;
    display: flex;
    flex-wrap: wrap;

    strong {
      padding: 0 8px 0 0;
    }
  }

  .links {
    padding: 0 16px 16px;

    strong.main {
      font-size: 140%;
    }

    .source {
      padding: 5px 0 16px 16px;
    }

    a {
      font-size: 130%;
    }

    ul {
      padding: 5px 16px;
      margin: 0;
    }

    li {
      margin: 3px 16px;
      padding: 0;
    }
  }

  .bait-week {
    display: flex;
    flex-direction: column;
    padding: 0 16px 16px;

    strong {
      font-size: 120%;
    }

    a {
      font-size: 120%;
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      max-width: 400px;
      margin: 0 auto;
    }

    .name {
      text-align: center;
      padding-bottom: 5px;
    }

    .btn-wrapper {
      text-align: center;
      padding: 5px 0;
    }

    .desc {
      text-align: justify;
    }

    img {
      display: block;
      margin: 0 auto;
      max-width: 200px;
      max-height: 200px;
    }
  }
}
</style>
