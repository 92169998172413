<template>
<div class="voice">
  <v-card>
    <v-card-title primary-title>
      <h2>Voice Assistants</h2>
    </v-card-title>
    <v-card-text>
      <v-divider></v-divider>
      <newsletter-dialog></newsletter-dialog>
      <v-divider></v-divider>
      <v-tabs v-model="active" fixed-tabs>
        <v-tab class="icon">
          <img :src="images['alexa-icon']" alt="Alexa">&nbsp;&nbsp;Amazon Alexa
        </v-tab>
        <v-tab class="icon">
          <img :src="images['google-icon']" alt="Google Home">&nbsp;&nbsp;Google Assistant
        </v-tab>
        <v-tab-item :key="0">
          <alexa></alexa>
        </v-tab-item>
        <v-tab-item :key="1">
          <google></google>
        </v-tab-item>
      </v-tabs>
    </v-card-text>
  </v-card>
</div>
</template>
<script>
import google from './voice-google.vue';
import alexa from './voice-alexa.vue';
import NewsletterDialog from '../widgets/newsletter.vue';

export default {
  data() {
    return {
      title: "Voice Assistants",
      active: 0,
      images: IMAGES
    };
  },
  components: {
    google: google,
    alexa: alexa,
    NewsletterDialog
  }
};
</script>
<style lang="less">
@import "../media.less";

.voice {
  .v-tabs__div {
    margin: 0 !important;
  }

  .v-tab.icon {
    img {
      width: 28px;
    }
  }

  .v-slide-group__prev {
    display: none !important;
  }
}

.assistant {
  margin-top: 15px;

  ul {
    font-size: 130%;
    margin: 0 0 0 2em;
    padding: 0;

    @media @for-phone {
      margin: 0;
    }
  }

  li {
    margin: 0 0 20px 0;
    padding: 0;
  }

  .indent {
    padding-left: 20px;
  }

  em {
    font-weight: 900;
  }
}
</style>
