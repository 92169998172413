<template>
<div>
  <hourly
    v-if="current.climacellv4Hourly"
    :current="current.climacellv4Hourly"
    :refresh="refresh"
  ></hourly>
  <weekly v-if="current.climacellv4Daily" :forecast="current.climacellv4Daily"></weekly>
</div>
</template>
<script>
import hourly from './hourly-forecast.vue';
import weekly from './week-forecast.vue';
import LoadMixin from './load-mixin.js';

export default {
  mixins: [LoadMixin],
  data() {
    return {
      title: 'Forecast',
      current: Object.assign({}, DATA)
    }
  },
  components: {hourly, weekly},
  methods: {
    refresh() {
      this.$fetch_data()
        .then(() => {
          this.current = Object.assign({}, DATA);
        })
        .catch((error) => {
          alert('Error fetching data');
        });
    }
  }
};
</script>
