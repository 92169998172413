<script>
import plugins from './utils/plugins.js';

export default {
  data() {
    return {
      favicon: FAVICON,
      now: NOW,
      release: RELEASE,
      active: 0,
      loaded: false,
      last_update: null,
      show_release: false,
      images: IMAGES,
      platform: PLATFORM
    };
  },
  created() {
    this.fetch_data();

    if (PLATFORM == 'native') {
      plugins.SplashScreen.hide();
      document.querySelector('html').classList.add('native');
      document.querySelector('body').classList.add('native');
    }
  },
  methods: {
    fetch_data() {
      var diff = null;
      if (this.last_update) {
        diff = (Date.now() - this.last_update) / 1000;
      }

      if (diff === null || diff > 60 * 5) {
        console.log('Fetching data', Date.now() / 1000);
        this.$fetch_data()
          .then((data) => {
            this.loaded = true;
            this.last_update = Date.now();

            this.show_release = false;
            // if (data.release != RELEASE && PLATFORM != 'native') {
            //   this.show_release = true;
            // }
          })
          .catch((error) => {
            if (!this.last_update) {
              alert('Error fetch data');
            }
          });
      }

      setTimeout(() => { this.fetch_data() }, 1000 * 5);
    },
    do_update() {
      console.log("force update");
      console.log(REGISTRATION);

      if (REGISTRATION) {
        // clear cache
        this.clear_all_cache()
          .then(() => {
            return REGISTRATION.unregister();
          })
          .then(result => {
            setTimeout(() => {
              location.reload();
            }, 100);
          })
          .catch(e => {
            console.error(e);
            alert("Error updating app.");
          });
      }
    },
    clear_all_cache() {
      return new Promise(function(resolve, reject) {
        if (
          "serviceWorker" in navigator &&
          navigator.serviceWorker.controller &&
          navigator.serviceWorker.controller.postMessage
        ) {
          var msg_chan = new MessageChannel();
          msg_chan.port1.onmessage = function(event) {
            console.log("Cache:", event.data);
            resolve();
          };

          navigator.serviceWorker.controller.postMessage({ task: "clear" }, [
            msg_chan.port2
          ]);
        } else {
          resolve();
        }
      });
    }
  }
};
</script>
<template>
<v-app>
  <div class="notch"></div>
  <div class="notch2"></div>
  <v-app-bar app dense color="primary" hide-on-scroll>
    <v-toolbar-title>
      <img :src="favicon" alt=""/>
      <div>CanyonLake.app</div>
    </v-toolbar-title>
  </v-app-bar>
  <v-content>
    <v-container class="main-container">
      <!-- <div id="ads">
        <a href="https://www.signupgenius.com/go/30E0844A9AC2BAAFA7-47976926-spring" target="_blank">
          Keep Canyon Lake Beautiful<br>Spring Clean Up<br>
          April 13<sup>th</sup> @ 9am - Noon<br>
          Click Here to Sign Up
          <img src="{% static 'ads/race-to-the-pantry.png' %}" alt="Race to the Pantry">
        </a>
      </div> -->
      <div class="release" v-if="show_release">
        New Version Available:
        <v-btn color="primary" @click="do_update()">Update Now</v-btn>
      </div>
      <div v-if="!loaded" class="loading">
        Loading data ..
      </div>
      <router-view></router-view>
      <footer>
        <div class="text-center" v-if="platform == 'web'">
          <a href="https://apps.apple.com/us/app/canyonlake-app/id1510001453" target="_blank">
            <img src="./icons/appledl.png" alt="Download on the App Store" style="height: 40px;">
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.canyonlake.app" target="_blank">
            <img src="./icons/googledl.png" alt="Download on Google Play"  style="height: 40px;">
          </a>
        </div>
        <div class="text-center">
          &copy; {{ now }}
          &nbsp; &sdot; &nbsp;
          <router-link :to="{name: 'help'}">Help</router-link>
          &nbsp; &sdot; &nbsp;
          <router-link :to="{name: 'privacy'}">Privacy</router-link>
          &nbsp; &sdot; &nbsp;
          v{{ release }}
        </div>
      </footer>
      <v-bottom-navigation grow app>
        <v-btn to="/">
          <span>Home</span>
          <v-icon>mdi-home</v-icon>
        </v-btn>
        <v-btn to="/forecast">
          <span>Forecast</span>
          <v-icon>mdi-white-balance-sunny</v-icon>
        </v-btn>
        <v-btn to="/fishing">
          <span>Water</span>
          <v-icon>mdi-waves</v-icon>
        </v-btn>
        <v-btn to="/events">
          <span>Events</span>
          <v-icon>mdi-calendar</v-icon>
        </v-btn>
      </v-bottom-navigation>
    </v-container>
  </v-content>
</v-app>
</template>
<style lang="less">
.v-app-bar {
  .v-toolbar__title {
    justify-content: center;
    align-items: center;
    line-height: normal;
    font-weight: bold;
  }
}

.nav-tabs {
  .v-tabs__div {
    width: 48px;
  }

  .v-tabs__slider-wrapper {
    width: 48px !important;
  }
}

.v-item-group.v-bottom-navigation .v-btn {
  min-width: 50px !important;
}

.main-container {
  > .v-card {
    margin-bottom: 10px;
  }

  footer {
    padding-bottom: 46px;

    > div {
      padding: 10px 0;
    }
  }
}
</style>
