<template>
<v-card class="hourly">
  <v-card-title primary-title class="flex-row align-center">
    <h2>Next 6 Hours</h2>
    <v-spacer></v-spacer>
    <div>
      <v-btn icon color="primary" @click="refresh()">
        <v-icon>mdi-cloud-refresh</v-icon>
      </v-btn>
    </div>
  </v-card-title>
  <div class="radar-link">
    <v-btn text color="primary" to="/forecast/maps?back=forecast">
      <v-icon>mdi-satellite</v-icon>&nbsp;Weather Maps
    </v-btn>
  </div>
  <trends v-if="current" :current="current" show-labels></trends>
  <div class="hours" v-if="current">
    <div v-for="f in current" :key="f.dt">
      <div class="tod">{{ f.dayname }}, {{ f.hour }}</div>
      <current-weather :current="f" :size="40"></current-weather>
    </div>
  </div>
  <v-divider></v-divider>
  <newsletter-dialog></newsletter-dialog>
</v-card>
</template>
<script>
import trends from './widgets/trends.vue';
import currentWeather from './current-weather.vue';
import NewsletterDialog from './widgets/newsletter.vue';

export default {
  props: ['current', 'refresh'],
  computed: {},
  components: {
    trends,
    currentWeather,
    NewsletterDialog
  }
};
</script>
<style lang="less">
.hourly {
  .hours {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .radar-link {
    text-align: center;
  }

  .tod {
    font-weight: bold;
    text-align: center;
  }

  canvas {
    width: 40px;
    height: 40px;
  }

  .trend {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px 20px 20px;

    .label {
      font-size: 80%;
      padding-right: 7px;
    }

    .data {
      font-size: 80%;
    }

    svg {
      max-width: 450px;
      height: 70px;

      text {
        font-size: 7px !important;
      }
    }

    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  }

  .current-weather {
    padding: 0 13px 30px 13px;
    font-size: 1.2rem;
    flex-direction: column;

    > div {
      padding: 0 8px;
      text-align: center;

      i {
        font-size: 40px;
      }

      .small {
        font-size: 0.9rem;
      }
    }
  }
}
</style>
